import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { 
SectionAlternate, 
Section,
} from 'components/organisms';
import { 
  QuoteForm, 
  Hero, 
  Providers, 
  Subscription, 
  YouTubeVideoModal,
  SearchResults,
 } from 'components/windrate';
import { SectionHeader } from 'components/molecules';
import { Typography } from '@material-ui/core';
import { useQueryParam } from 'gatsby-query-params';

import pagedata from "content/pages/index.yaml";
import subscription from "content/global/subscription.yaml";
const chartData = require('content/global/searchdata.json');

const providers = pagedata.providers; 

const useStyles = makeStyles(theme => ({
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
  sectionAlternateEx: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },
  noBottomPadding: {
    paddingBottom: 0,
  }, 
  fontWeight900: {
    fontWeight: 900,
  },  
  lessPadding: {
    paddingTop: '3rem !important',
    paddingBottom: '3rem !important',
  },    
}));

const Subtext = props => {
  const { data } = props; 
  const classes = useStyles();   
  return (
    <div> 
      <SectionHeader
        title={
          <span>
            {data.title}{' '}
            <Typography component="span" variant="inherit" color="primary">
              {data.emphasized}
            </Typography>
          </span>
        }
        subtitle= {
          <span style={{fontSize: "1.3em"}} >
              <span dangerouslySetInnerHTML={{__html:data.subtitle}}>
              </span>
          <br/>
          <Typography component="span" variant="h6" color="primary"><strong dangerouslySetInnerHTML={{__html:data.subtitleEmphasized}}></strong></Typography>
          </span>
        }
        titleProps={{ className: classes.fontWeight900 }}
        fadeUp
      />
	</div>
  );
};


const LandingPageView2 = props => {  
  const location = useQueryParam("location", "any");
  const classes = useStyles();
  const { data } = props;

  return (
    <div>
      <YouTubeVideoModal />
      <Hero className={classes.noBottomPadding} data={data.hero} />
      <SectionAlternate className={classes.sectionAlternate}>
      <QuoteForm data={data.quoteForm} location={location} />    
      </SectionAlternate>
      {/*
      <Section className={classes.noBottomPadding}>
        <SearchResults 
            location='any'
            provider={data.quoteForm.quote_provider || 'any'}
            data={chartData}
          />
      </Section>
  */}
      <Section className={classes.noBottomPadding}>
        <Subtext data={data.subText} />
      </Section>
      <Section className={classes.lessPadding}>
          <Providers data={providers} />
      </Section>
      <SectionAlternate innerNarrowed className={classes.sectionAlternateEx}>
          <Subscription data={subscription} />
      </SectionAlternate>
    </div>
  );
}

export default LandingPageView2;
